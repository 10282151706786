import { BRAND_DOMAIN, BRAND_NAME } from '../constants/Branding';
const baseUrl = BRAND_NAME.toLowerCase();

export const DevUrlsMap = {
  REACT_APP_REST_API_URL: `https://dev-user-api.sky11.com/api/v1`,
  REACT_APP_REST_REPORTING_API_URL: `https://dev-reporting.sky11.com`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://dev-reporting.sky11.com/push-notifications`,
  REACT_APP_REST_V2_API_URL: `https://dev-user-api.sky11.com`,
  REACT_APP_GRAPHQL_API_URL: `https://dev-user-api.sky11.com/graphql/`,
  REACT_APP_REST_LAMBDA_API_URL: `https://kjc4f3bczd.execute-api.eu-west-2.amazonaws.com/dev`,
};

export const StageUrlsMap = {
  REACT_APP_REST_API_URL: `https://stage-user-api.${BRAND_DOMAIN}/api/v1`,
  REACT_APP_REST_REPORTING_API_URL: `https://stage-reporting.${BRAND_DOMAIN}`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://stage-reporting.${BRAND_DOMAIN}/push-notifications`,
  REACT_APP_REST_V2_API_URL: `https://stage-user-api.${BRAND_DOMAIN}`,
  REACT_APP_GRAPHQL_API_URL: `https://stage-user-api.${BRAND_DOMAIN}/graphql/`,
  REACT_APP_REST_LAMBDA_API_URL: `https://kjc4f3bczd.execute-api.eu-west-2.amazonaws.com/dev`,
};

export const ProdUrlsMap = {
  REACT_APP_REST_API_URL: `https://api.${BRAND_DOMAIN}/api/v1`,
  REACT_APP_REST_REPORTING_API_URL: `https://reporting.${BRAND_DOMAIN}`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://reporting.${BRAND_DOMAIN}/push-notifications`,
  REACT_APP_REST_V2_API_URL: `https://api.${BRAND_DOMAIN}`,
  REACT_APP_GRAPHQL_API_URL: `https://api.${BRAND_DOMAIN}/graphql/`,
  REACT_APP_REST_LAMBDA_API_URL: `https://kjc4f3bczd.execute-api.eu-west-2.amazonaws.com/dev`,
};

export const EnvUrlsMap = {
  development: DevUrlsMap,
  stage: StageUrlsMap,
  production: ProdUrlsMap,
};

export function getBaseUrl(env: string, url: string) {
  return EnvUrlsMap[env][url];
}
